import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from "../Components/Logo.png";
import { ArrowBack } from '@mui/icons-material';
import { Upload } from 'lucide-react';

export default function SimpleComponent() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    linkedin: '',
    cv: null,
    agreeToTerms: false,
  });

  const token = localStorage.getItem('token');
  const { jobId } = useParams(); // Get jobId from the route params
  const [job, setJob] = useState(null); // State to hold job details
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData(prev => ({ 
      ...prev, 
      cv: file 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('linkedin', formData.linkedin);
    formDataToSend.append('cv', formData.cv); // Attach the CV file
    formDataToSend.append('jobId', jobId); // Attach the jobId
  
    try {
      const response = await axios.post('http://localhost:8080/apply-job', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        alert('Application submitted successfully');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    }
  };
  

  // Fetch job details and user info when component mounts
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:8080/jobs/${jobId}`);
        setJob(response.data);
        setLoading(false)
      } catch (error) {
        setError("İş ilanı bulunamadı");
      } 
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get('http://localhost:8080/profile', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setFormData(prev => ({
          ...prev,
          firstName: response.data.name || '',
          lastName: response.data.surname || '',
          email: response.data.email || '',
        }));
      } catch (err) {
        navigate("/login")
        setError("Kullanıcı bilgileri alınamadı.");
        
      } 
    };

    fetchUser();
    fetchJobDetails();
  }, [jobId, token,navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={Logo} alt="logo" className='h-32 w-auto animate-pulse-grow' />
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
      <div className="w-full lg:w-2/3 p-8">
        <header className="mb-8">
          <div className="flex flex-wrap gap-5 justify-between items-center mb-4">
            <div className="flex gap-2 items-center">
              <Link to="/acikpozisyonlar" className='p-2 border rounded-full'>
                <ArrowBack />
              </Link>
              <span className="my-auto text-2xl font-bold text-neutral-800">Geri Dön</span>
            </div>
          </div>
        </header>

        <main>
          <h2 className="text-2xl mb-8 font-semibold">Formu doldurarak başvur.</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <div className="flex">
                <label htmlFor="firstName" className="block mb-2">İsim</label> <label htmlFor="required" className='text-red-600'>*</label>
                </div>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Adınızı girin"
                  className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
              <div>
                <div className="flex">
                <label htmlFor="lastName" className="block mb-2">Soyisim</label><label htmlFor="required" className='text-red-600'>*</label>
                </div>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Soyadınızı girin"
                  className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
            
            <div>
            <div className="flex">
              <label htmlFor="email" className="block mb-2">Email</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email adresinizi girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
            <div className="flex">
              <label htmlFor="phone" className="block mb-2">Telefon</label><label htmlFor="required" className='text-red-600'>*</label>
              </div>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Telefon numaranızı girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
                required
              />
            </div>
            </div>
            <div>
              <label htmlFor="linkedin" className="block mb-2">Linkedin</label>
              <input
                type="text"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInputChange}
                placeholder="LinkedIn profilinizi girin"
                className="w-full p-3 rounded border border-[#EFEFEE] focus:border-blue-500 focus:outline-none"
              />
            </div>
            <div>
              <label className="block mb-2 font-semibold">CV veya Portfolyo</label>
              <div className="flex flex-col items-center p-6 border-2 border-dashed border-blue-600 rounded-xl bg-blue-50 text-blue-600">
                <Upload/>
                <p>
                  {formData.cv ? (
                    <span className="text-blue-600">{formData.cv.name}</span>
                  ) : (
                    'Dosyaları sürükleyin veya '
                  )}
                  <label htmlFor="cv" className="cursor-pointer underline">yükleyin</label>
                </p>
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  onChange={handleFileChange}
                  className="hidden"
                  accept=".pdf,.doc,.docx"
                />
              </div>
            </div>
            <div className="flex items-start">
              <input
                type="checkbox"
                id="agreeToTerms"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleInputChange}
                className="mt-1 mr-2"
                required
              />
              <label htmlFor="agreeToTerms" className="text-sm">
                <a href="a" className="text-neutral-900 underline">Kullanım Şartları</a> ve <a href="a" className="text-neutral-900 underline">Gizlilik Politikası</a>'nı kabul ediyorum
              </label>
            </div>
            <button type="submit" className="w-full py-4 bg-[#135361] text-white rounded-full text-lg font-medium hover:bg-[#0f4c4d] transition">
              Başvur
            </button>
          </form>
        </main>
      </div>

      {/* Sağ Taraf: Kart */}
      <aside className="w-full lg:w-1/3 bg-blue-100 p-8 flex items-center justify-center">
        <div className="bg-slate-100 rounded-3xl p-8 max-w-md">
          <h2 className="text-xl font-bold text-neutral-800 mb-1">{job.title}</h2>
          <p className="text-sm text-slate-500 mb-4">{job.department}</p>

          <div className="flex flex-wrap gap-2 mb-8">
            {job.skills.map((skill, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-gray-200 rounded-md text-xs font-medium text-gray-600">
                {skill}
              </span>
            ))}
          </div>
          <hr className="border-gray-200 mb-8" />
          <h3 className="text-2xl font-bold mb-4 text-stone-950">
            UFK Teknoloji Takımına katılarak kendini geliştir.
          </h3>
          <ul className="list-disc pl-5 mb-8 text-sm text-stone-950">
            <li>Takımımıza katılarak, yenilikçi projeler geliştirebilirsiniz</li>
            <li>Yeni beceriler kazan</li>
            <li>Takımımızda, farklı disiplinlerden gelen insanlarla birlikte çalış</li>
            <li>Teknofest ve yarışma deneyimleri elde et</li>
            <li>Networkünü geliştir</li>
          </ul>
          <p className="text-[#9598B2] font-[Objectivity] text-[12px] font-normal leading-[130%] text-center">
            Takımımız üniversite öğrencisi ve mezunlarından oluşan genç ve dinamik bir ekiptir. Maddi çıkar gözetmiyor, gönüllü olarak çalışıyoruz. Kazanırsak beraber kazanacağız, kaybedersek beraber kaybedeceğiz.
          </p>
        </div>
      </aside>
    </div>
  );
}
