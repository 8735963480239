import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLoading } from '../../LoadingContext';
import { Avatar } from '@mui/material';
import { Menu } from '@headlessui/react';

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [userForm, setUserForm] = useState({ name: '', email: '', role: 'user' });
  const { addLoadingSource, removeLoadingSource } = useLoading();

  useEffect(() => {
    // Fetch users from the API
    axios.get('http://localhost:8080/api/users')
      .then(response => {
        setUsers(response.data);
        removeLoadingSource('users');
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, [removeLoadingSource]);

  // Function to handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserForm({ ...userForm, [name]: value });
  };

  // Function to open edit dialog with user data
  const handleEditClick = (user) => {
    setEditingUser(user);
    setUserForm({ name: user.name || '', email: user.email || '', role: user.role || 'user' });
  };

  // Function to handle form submission for updating user
  const handleUpdateUser = () => {
    axios.put(`http://localhost:8080/api/users/${editingUser._id}`, userForm)
      .then(response => {
        // Ensure the updated user data is set correctly in state
        setUsers(users.map(user => (user._id === editingUser._id ? { ...response.data } : user)));
        setEditingUser(null);
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
  };

  // Function to delete a user
  const handleDeleteUser = (userId) => {
    axios.delete(`http://localhost:8080/api/users/${userId}`)
      .then(() => {
        setUsers(users.filter(user => user._id !== userId));  // Remove the user from the list
      })
      .catch(error => {
        console.error('Error deleting user:', error);
      });
  };

  if (!users) return addLoadingSource('users');

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Users</h1>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Role</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id} className="hover:bg-gray-100">
                <td className="border px-4 py-2">
                  <div className="flex items-center gap-2">
                    {user.profile_picture ? (
                      <Avatar alt={user.name} src={user.profile_picture} />
                    ) : (
                      <Avatar>{user.name?.[0]?.toUpperCase() || ''}</Avatar>
                    )}
                    {user.name || 'Unnamed'} {user.surname || ''}
                  </div>
                </td>
                <td className="border px-4 py-2">{user.email}</td>
                <td className="border px-4 py-2">{user.role}</td>
                <td className="border px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2"
                    onClick={() => handleEditClick(user)}
                  >
                    Update
                  </button>
                  <button
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit User Dialog */}
      {editingUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md w-96">
            <h2 className="text-xl font-bold mb-4">Edit User</h2>
            <input
              className="border p-2 w-full mb-4"
              name="name"
              placeholder="Name"
              value={userForm.name}
              onChange={handleInputChange}
            />
            <input
              className="border p-2 w-full mb-4"
              name="email"
              placeholder="Email"
              value={userForm.email}
              onChange={handleInputChange}
            />

            {/* Role Selector with Headless UI */}
            <Menu as="div" className="relative inline-block text-left mb-4">
              <Menu.Button className="bg-gray-200 px-4 py-2 rounded">
                {userForm.role}
              </Menu.Button>
              <Menu.Items className="absolute z-10 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } px-4 py-2 w-full text-left`}
                      onClick={() => setUserForm({ ...userForm, role: 'admin' })}
                    >
                      Admin
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } px-4 py-2 w-full text-left`}
                      onClick={() => setUserForm({ ...userForm, role: 'user' })}
                    >
                      User
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>

            <div className="flex justify-end">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                onClick={() => setEditingUser(null)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleUpdateUser}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
