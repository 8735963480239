import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { Switch } from "@headlessui/react";

const JobsPage = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmDeleteJobId, setConfirmDeleteJobId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get("http://localhost:8080/jobs");
        setJobs(response.data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const toggleJobStatus = async (jobId, currentStatus) => {
    const newStatus = !currentStatus;

    try {
      await axios.patch(`http://localhost:8080/jobs/${jobId}`, { active: newStatus });
      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.id === jobId ? { ...job, active: newStatus } : job
        )
      );
    } catch (error) {
      console.error("Error toggling job status:", error);
    }
  };

  const deleteJob = async (jobId) => {
    try {
      await axios.delete(`http://localhost:8080/jobs/${jobId}`);
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
    } catch (error) {
      alert("Error deleting job: " + error.response.data.message);
    }
  };

  const handleDeleteClick = (jobId) => {
    setConfirmDeleteJobId(jobId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (confirmDeleteJobId) {
      await deleteJob(confirmDeleteJobId);
      setShowModal(false);
      setConfirmDeleteJobId(null);
    }
  };

  const Duyuru = ({ trigger, children }) => {
    const [isOpen, setIsOpen] = useState(trigger);

    useEffect(() => {
      setIsOpen(trigger);
    }, [trigger]);

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleBackdropClick = (e) => {
      if (e.target.id === 'backdrop') {
        handleClose();
      }
    };

    if (!isOpen) return null;

    return (
      <div
        id="backdrop"
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        onClick={handleBackdropClick}
      >
        <div className="bg-white rounded-2xl shadow-lg w-full max-w-md h-auto max-h-[90vh] p-8 relative flex flex-col items-center">
          <button
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
            onClick={handleClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="w-full h-full rounded-lg overflow-auto">
            {children}
          </div>
        </div>
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Job Listings</h1>
      <Link to="/admin/add-job" className="mb-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Add New Job
      </Link>

      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Department</th>
            <th className="py-2 px-4 border-b">Skills</th>
            <th className="py-2 px-4 border-b">Active</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.id} className="border-b">
              <td className="py-2 px-4">{job.title}</td>
              <td className="py-2 px-4">{job.department}</td>
              <td className="py-2 px-4">{job.skills.join(", ")}</td>
              <td className="py-2 px-4">
                <Switch
                  checked={job.active}
                  onChange={() => toggleJobStatus(job.id, job.active)}
                  className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors ${
                    job.active ? "bg-blue-600" : "bg-gray-200"
                  }`}
                >
                  <span
                    className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                      job.active ? "translate-x-6" : "translate-x-1"
                    }`}
                  />
                </Switch>
              </td>
              <td className="py-2 px-4">
                <Link to={`/admin/jobs/${job.id}/edit`} className="text-blue-500 hover:underline mr-4">
                  Düzenle
                </Link>
                <Link to={`/admin/jobs/${job.id}/applications`} className="text-blue-500 hover:underline mr-4">
                  Başvurular
                </Link>
                <button onClick={() => handleDeleteClick(job.id)} className="text-red-500 hover:underline">
                  Sil
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Duyuru Modal for Confirmation */}
      <Duyuru trigger={showModal}>
        <h2 className="text-lg font-bold">Confirm Deletion</h2>
        <p>Are you sure you want to delete this job?</p>
        <div className="flex justify-end mt-4">
          <button className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400" onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </Duyuru>
    </div>
  );
};

export default JobsPage;
