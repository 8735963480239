import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { LinkedIn } from "@mui/icons-material";

const ApplicationsPage = () => {
  const { jobId } = useParams();
  const [applications, setApplications] = useState([]);
  const [job, setJob] = useState(""); // İşin adını saklamak için durum
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(`http://localhost:8080/api/applications?job_id=${jobId}`);
        setApplications(response.data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    const fetchJobTitle = async () => {
      try {
        const response = await axios.get(`http://localhost:8080/jobs/${jobId}`); // İş bilgilerini almak için API çağrısı
        setJob(response.data); // İşin adını ayarlayın
      } catch (error) {
        console.error("Error fetching job title:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
    fetchJobTitle();
  }, [jobId]);

  if (loading) return <div className="text-center text-lg py-4">Loading...</div>;

  const formatApplicationDate = (dateString) => {
    const applicationDate = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Istanbul',
      hour12: false
    };
    return new Intl.DateTimeFormat('tr-TR', options).format(applicationDate);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-6">Başvuru Listesi: {job.title}</h1> 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {applications.map((application, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 border border-gray-200"
          >
            <h2 className="text-xl font-bold mb-2">{application.firstName} {application.lastName}</h2>
            <p className="text-gray-600 mb-2">
              <strong>Telefon:</strong> {application.phone}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Email:</strong> {application.email}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Tarih:</strong> {formatApplicationDate(application.application_date)}
            </p>
            {application.cv_path ? (
              <Link
                to={`http://localhost:8080/${application.cv_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                View CV
              </Link>
            ) : (
              <span className="text-gray-400 cursor-not-allowed">View CV (Unavailable)</span>
            )}

            {application.linkedin && (
              <Link
                to={`${application.linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                <LinkedIn />
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationsPage;
