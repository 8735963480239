import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function VerifyEmailPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [basarili,setBasarili] = useState("E-Posta Doğrulanıyor");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.get('http://localhost:8080/verify-email', { params: { token } });
        setBasarili('E-posta doğrulama başarılı!');
        navigate("/login")

      } catch (error) {
        setBasarili('E-posta doğrulama başarısız: ' + error.response.data.message);
      }
    };

    verifyEmail();
  }, [token,navigate]);

  return (
    <div className=' min-h-screen items-center justify-center flex'>
      <h1>{basarili}</h1>
    </div>
  );
}

export default VerifyEmailPage;
