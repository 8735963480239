import React from 'react'
import CSS from "./Hakkimizda.css"

function Hakkimizda() {

  



  return (
    <div className='min-h-screen flex flex-col '>
  <link
    href={CSS}
    rel="stylesheet"
    type="text/css"
  />



  <div className="section-header flex-grow -z-10">
    <div className="container">
      <div className="w-layout-grid header-grid">
        <div className="header-block">
          <h1 className="hero-heading">Hakkımızda</h1>
          <p className="paragraph-regular-3">
            Bizler, karşılaşılan problemleri sistemli bir şekilde çözmeyi hayat
            felsefesi olarak benimsemiş <strong>Üniversite</strong>{" "}
            öğrencileriyiz. <br />
            <br />
            <strong>UFK Teknoloji Takımı</strong> adı altında 2023 yılından itibaren teknolojik
            yenilikler ve fikirler üretmek üzere çalışmalar yapıyoruz.
            Üyelerimiz, yapay zeka üzerine çeşitli çalışmalar yapmıştır. Bu
            alanlarda tecrübeler edinen ekip, <strong>Milli Teknoloji Hamlesi</strong> 'ne  katkı
            sağlayacak şekilde yapay zeka teknolojileri alanında projeler yapıp,
            yerli ve milli uygulamalar geliştirmeyi hedeflemektedir.
          </p>
        </div>
        <div
          id="w-node-_710de8f0-d55b-0ebf-88f0-15d583a62353-ab56c44b"
          className="header-image"
        >
          <img
            src="https://cdn.prod.website-files.com/66945ac04a8680c762a4f7c7/66bb0c7d7217523adb88d2c0_P1102620.JPG"
            loading="lazy"
            width={544}
            sizes="90vw"
            alt="Team"
            className="header-image-graphic"
          />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Hakkimizda