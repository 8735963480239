import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Avatar } from '@mui/material';


function stringAvatar(name) {
  const nameParts = name.trim().split(' ');
  const initials = nameParts.length > 1 
    ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}` 
    : nameParts[0][0];

  return {
    children: initials.toUpperCase(),
  };
}

function ProfilePage() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const response = await axios.get('http://localhost:8080/profile', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        setUserData(response.data);
    } catch (err) {
        setError('Error fetching user data');
        if (err.response && err.response.status === 401) {
            navigate('/login');
        }
    }
  }, [navigate]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post('http://localhost:8080/upload-profile-picture', formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        fetchUserData(); 
      } catch (err) {
        setError('Error uploading profile picture');
      }
    }
  };

  const handleFileRemove = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.delete('http://localhost:8080/remove-profile-picture', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      fetchUserData(); // Refresh user data
    } catch (err) {
      setError('Error removing profile picture');
    }
  };

  const triggerFileSelect = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (error) return <div>{error}</div>;
  if (!userData) return <div>Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen">
      

      <div className="z-0">
        <div className="flex flex-grow justify-center container z-0">
          <aside className="hidden py-4 md:block w-1/3">
            <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-blue-100 top-12">
              <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>
              <Link  className="flex items-center px-3 py-2.5 font-bold bg-white text-blue-900 border rounded-full">
                Public Profile
              </Link>
              <Link  className="flex items-center px-3 py-2.5 font-semibold hover:text-blue-900 hover:border hover:rounded-full">
                Account Settings
              </Link>
            </div>
          </aside>
          <main className="w-full py-1">
            <div className="p-2 md:p-4">
              <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                <h2 className="pl-6 text-2xl font-bold sm:text-xl">Public Profile</h2>

                <div className="grid max-w-2xl mx-auto mt-8">
                  <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
                    <div className="object-cover p-1 rounded-full ring-2 ring-blue-300">
                      <Avatar
                        alt="avatar"
                        sx={{ width: 160, height: 160 }}
                        src={userData.profile_picture || undefined}
                        {...(!userData.profile_picture && stringAvatar(`${userData.name} ${userData.surname}`))}
                      />
                    </div>

                    <div className="flex flex-col space-y-5 sm:ml-8">
                      <button
                        type="button"
                        onClick={triggerFileSelect}
                        className="py-3.5 px-7 text-base font-medium text-blue-100 focus:outline-none bg-[#202142] rounded-lg border border-blue-200 hover:bg-blue-900 focus:z-10 focus:ring-4 focus:ring-blue-200"
                      >
                        Profil Resmini Değiştir
                      </button>
                      <button
                        type="button"
                        onClick={handleFileRemove}
                        className="py-3.5 px-7 text-base font-medium text-blue-900 focus:outline-none bg-white rounded-lg border border-blue-200 hover:bg-blue-100 hover:text-[#202142] focus:z-10 focus:ring-4 focus:ring-blue-200"
                      >
                        Profil Resmini Kaldır
                      </button>

                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>

                  <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                    <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                      <div className="w-full">
                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                          Your first name
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          className="bg-blue-50 border border-blue-300 text-blue-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="Your first name"
                          value={userData.name}
                          required
                        />
                      </div>

                      <div className="w-full">
                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                          Your last name
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          className="bg-blue-50 border border-blue-300 text-blue-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="Your last name"
                          value={userData.surname}
                          required
                        />
                      </div>
                    </div>

                    <div className="mb-2 sm:mb-6">
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                        Your email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="bg-blue-50 border border-blue-300 text-blue-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={userData.email}
                        required
                      />
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
