import React from 'react';
import Header from '../Pages/Components/Header';
import Footer from '../Pages/Components/Footer';
import { useLoading } from '../LoadingContext'; // Adjust path as needed
import LoadingIndicator from '..//LoadingIndicator'; // Your loading indicator component

const MainLayout = ({ children, showFooter = true, classname }) => {
  const { loadingSources } = useLoading();
  const isLoading = loadingSources.size > 0;

  return (
    <div className={`${classname} pt-5`}>
      {isLoading && <LoadingIndicator />} {/* Show loading indicator if loading is true */}
      <Header />
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
