import React, { useState } from 'react';
import { Filter } from 'lucide-react';

const departments = [
  "Sosyal Medya",
  "Yazılım Geliştirme",
  "Sponsorluk",
  "Organizasyon"
];

const FilterButton = ({ onFilter, onClear }) => {
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Departman seçme işlemi
  const toggleDepartment = (department) => {
    if (selectedDepartments.includes(department)) {
      setSelectedDepartments(
        selectedDepartments.filter((d) => d !== department)
      );
    } else {
      setSelectedDepartments([...selectedDepartments, department]);
    }
  };

  // Filtreleme işlemi
  const handleFilter = () => {
    onFilter(selectedDepartments);
    setIsDropdownOpen(false);
  };

  // Filtreyi temizleme işlemi
  const handleClear = () => {
    setSelectedDepartments([]);
    onClear();
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative ">
      <button
        className="px-4 rounded-md text-gray-800 flex items-center gap-2 bg-ufkgray text-sm font-sans font-thin py-3"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Filter size={20} />
        Departmanlar
      </button>

      <div
        className={`absolute mt-2 sm:-ml-0 -ml-[8.7vh] bg-white rounded-md shadow-xl p-4 w-72 lg:w-[50vh] z-10 border-2 border-[#135361] transition-all duration-500 ease-in-out overflow-hidden ${
          isDropdownOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          {departments.map((department, index) => (
            <div
              key={index}
              className={`px-4 py-3 rounded-full border flex items-center justify-center cursor-pointer ${
                selectedDepartments.includes(department)
                  ? 'bg-primary text-white'
                  : 'text-gray-700'
              }`}
              onClick={() => toggleDepartment(department)}
            >
              {department}
            </div>
          ))}
        </div>

        <div className="bg-gray-200 h-[1px] mt-4"></div>
        <div className="mt-4 flex justify-between items-center">
          <div
            className="rounded-full border cursor-pointer px-5 py-2"
            onClick={handleClear}
          >
            Temizle
          </div>
          <button
            className="bg-secondary text-white px-8 py-2 rounded-xl"
            onClick={handleFilter}
          >
            Filtrele
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
